/*-- Variables --*/
:root {

    // Theme Color
    --color-primary: #805AF5;
    --color-primary-alt: #35316f;
    --color-secondary: #CD99FF;


    // Gredient Color 
    --color-primary-gradient-start: #805AF5;
    --color-primary-gradient-end: #CD99FF;

    // Typo Color 
    --color-heading: #ffffff;
    --color-body: #BCC3D7;
    --color-link: #BCC3D7;
    --color-text-off: #565e78;

    --color-dark: #21242D;
    --color-darker: rgba(6, 6, 6, 0.7);
    --color-darkest: rgba(0, 0, 0, 0.3);
    --color-lessdark: #2E313D;



    --color-black: #000000;
    --color-blackest: #16181E;
    --color-border: rgba(255, 255, 255, 0.05);
    --color-border-light: #e5e7eb;
    --color-border-dark: #1E1E1E;


    // Dark-Mode
    --dark-bg-1: #F8F8FF;
    --dark-bg-2: #212330;
    --dark-bg-3: #DDE7FF;
    --dark-bg-4: #EBEFFF;
    --dark-color-link: #55595C; // Dark Primary
    --color-border-2: #C2D4FF;
    --color-strock: #EFEFFF;

    --dark-gradient-1: linear-gradient(180deg, rgba(122, 151, 255, 0.19) 0%, rgba(248, 248, 255, 0.19) 100%);
    --dark-gradient-2: linear-gradient(90deg, #6A48F2 0%, #CD99FF 68.75%);
    --dark-gradient-3: linear-gradient(93deg, #D3E0FF 0.78%, rgba(221, 231, 255, 0.00) 97.43%);

    --dark-shadow-1: 0px 14px 36.7px 0px rgba(14, 12, 21, 0.06);
    --dark-shadow-2: 0px 26px 45.4px 0px rgba(186, 193, 225, 0.72);



    //Bg Color
    --color-bg-1: #0E0C15; // use in Dark

    // Gery Colors 
    --color-gray: #3E414B;
    --color-midgray: #878787;
    --color-light: #E4E6EA;
    --color-lighter: #CED0D4;
    --color-lightest: #F0F2F5;

    --color-white: #ffffff;
    --color-white-off: rgba(255, 255, 255, 0.05);



    // Notify Colors 
    --color-success: #3EB75E;
    --color-danger: #FF0003;
    --color-warning: #FFC876;
    --color-info: #1BA2DB;


    //Social icon colors
    --color-facebook: #3B5997;
    --color-twitter: #1BA1F2;
    --color-youtube: #ED4141;
    --color-linkedin: #0077B5;
    --color-pinterest: #E60022;
    --color-instagram: #C231A1;
    --color-vimeo: #00ADEF;
    --color-twitch: #6441A3;
    --color-discord: #7289da;

    // Extra Color 
    --color-extra01: #666666;
    --color-extra02: #606770;
    --color-extra03: #FBFBFD;
    --color-extra04: #1A1A1A;
    --color-extra05: #242424;
    --color-extra06: #111424;

    //Border-radius
    --border-width: 2px;
    --radius-small: 4px;
    --radius: 12px;
    --radius-big: 16px;
    --radius-bigger: 50px;



    //Font weight
    //primary font
    --p-light: 300;
    --p-regular: 400;
    --p-medium: 500;
    --p-semi-bold: 600;
    --p-bold: 700;
    --p-extra-bold: 800;
    --p-black: 900;


    //Shadows
    --shadow-primary: 0 0 20px 5px rgba(20, 126, 243, 0.1);
    --shadow-light: 1px 1px 6px rgba(0, 0, 0, 0.25);
    --shadow-lighter: 4px 4px 20px 20px rgba(0, 0, 0, 0.01);


    //transition easing
    --transition: 0.3s;
    --transition-transform: transform .65s cubic-bezier(.23, 1, .32, 1);

    //Font Family
    --font-primary: 'Sora', sans-serif;
    --secondary-font: 'Sora', sans-serif;
    --font-awesome: 'Font Awesome 6 Pro';



    //Fonts Size
    --font-size-b1: 16px;
    --font-size-b2: 14px;
    --font-size-b3: 12px;

    //Line Height
    --line-height-b1: 1.67; //28px
    --line-height-b2: 1.7; //24px
    --line-height-b3: 1.6; //22px
    --line-height-b3: 1.3; //16px

    // Heading Font 
    --h1: 60px; //52px 1.29
    --h2: 48px; //44px 1.23
    --h3: 32px; // 34px 1.14
    --h4: 24px; //30px  1.25
    --h5: 20px; //22px 1.24
    --h6: 18px; //20px 1.25
}

// Layouts Variation
$smlg-device: 'only screen and (max-width: 1199px)';
$extra-device: 'only screen and (min-width: 1600px) and (max-width: 1919px)';
$laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout: 'only screen and (min-width: 768px) and (max-width: 991px)';
$sm-layout: 'only screen and (max-width: 767px)';
$large-mobile: 'only screen and (max-width: 575px)';
$small-mobile: 'only screen and (max-width: 479px)';