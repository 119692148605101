/*------------------------
    Featue Styles  
--------------------------*/
.rbt-feature-main {
    padding-bottom: 0;
    border-radius: 10px;
    .feature-left-top {
        max-width: 500px;
    }
    .feature-left-bottom {
        max-width: 500px;

        .button-group {
            margin-bottom: 50px;
        }

       
       
    }
    .thumbnail {
       
    }
}